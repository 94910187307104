/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react'
import { Card, Flex } from 'theme-ui'
import Eclipse from '~/assets/images/icons/eclipse.svg'
import styles from './styles.module.css'

import type {
  IProduct,
  IRelatedProductProduct,
  ICatalogProduct,
} from 'graphql-cms/types'
import { calculateTimeLeftScrollingText, toUsdCurrency } from '~/utils'
import ResponsiveImage from '~/components/Generic/Image'

type IBasta = {
  sku: string
  endDate: Date
  active: boolean
  latestBid: number
}

interface Props {
  product: IProduct | ICatalogProduct | IRelatedProductProduct
  price: number
  basta: IBasta
  maxHeight: string
  viewType: string
}

const InternalProductCard: React.FC<Props> = ({
  product,
  price,
  basta,
  maxHeight,
  viewType,
}) => {
  const { mainImage, isActive: _isActive } = product
  const [bastaItemStringEndDate, setBastaItemStringEndDate] = useState<
    string | null
  >()
  const isActive =
    _isActive === undefined || _isActive === null || _isActive ? true : false
  const finalPrice = basta?.active ? basta?.latestBid : price
  const inactiveCard = !isActive ? { filter: 'grayscale(1)', opacity: 0.5 } : {}

  const initProduct = () => {
    setInterval(() => {
      const _date = basta?.endDate || new Date()
      setBastaItemStringEndDate(calculateTimeLeftScrollingText(_date))
    }, 300)
  }

  useEffect(() => {
    if (!bastaItemStringEndDate) {
      initProduct()
    }
  })

  return (
    <Card
      className={styles.productCard}
      sx={{ height: 'auto', maxHeight, ...inactiveCard }}
    >
      <Flex className={styles.mainImageContainer}>
        {mainImage && <ResponsiveImage image={mainImage} />}
      </Flex>
      <div
        className={[
          styles.subTextDiv,
          viewType === 'index' && styles.subTextDiv_index,
          basta?.active && styles.bastaActive,
        ].join(' ')}
      >
        <div>
          <div
            className={[
              styles.subTextIcon,
              viewType === 'index' && styles.subTextIcon_index,
            ].join(' ')}
          >
            {basta?.active && <Eclipse />}
          </div>
          <span
            className={[
              styles.subText,
              viewType === 'index' && styles.subText_index,
            ].join(' ')}
          >
            {basta?.active && `LATEST BID`}
          </span>
        </div>
        <span
          className={[
            styles.subText,
            viewType === 'index' && styles.subText_index,
          ].join(' ')}
        >
          {toUsdCurrency(finalPrice)} USD
        </span>
      </div>
    </Card>
  )
}

export default InternalProductCard
