import { IMAGE_FRAGMENT, IImage } from './image'
import { IProductCategory } from './product-category'

export const SLIM_EXTERNAL_PRODUCT_FRAGMENT = `
  sys {
    id
  }
  __typename
  categoriesCollection {
    items {
      name
    }
  }
  mainImage {
    ${IMAGE_FRAGMENT}
  }
  name
  price
  url
`

export interface IExternalProduct {
  sys: {
    id: string
  }
  __typename: 'ExternalProduct'
  categoriesCollection?: {
    items: IProductCategory[]
  }
  mainImage: IImage
  name: string
  price?: number
  url: string
}
