import type {
  IProduct,
  IRelatedProductProduct,
  IRelatedProductExternal,
  IRelatedProduct,
  IBundle,
  IExternalProduct,
  ICatalogBundle,
  IRelatedProductBundle,
  ICatalogItem,
  ICatalogProduct,
} from 'graphql-cms/types'
import type { Product, ProductOptionValue } from '@chordcommerce/react-autonomy'
import { uniqueByKey } from '~/utils/compare'

/**
 * Serializes a Bundle into a Product for react-autonomy methods.
 */
export const serializeBundle = (
  bundle: IBundle | ICatalogBundle | IRelatedProductBundle
): Product => {
  return {
    url: `${
      typeof window !== 'undefined' ? window.location.origin : ''
    }/bundles/${bundle.slug}/`,
    isBundle: true,
    price: bundle.price.toString(),
    variant: {
      description: bundle.shortDescription ?? '',
      images: [{ largeUrl: bundle?.mainImage?.url ?? '' }],
      name: bundle.name,
      price: bundle.price.toString(),
      optionValues: [],
      sku: bundle.masterSku,
      slug: bundle.slug,
    },
  }
}

/**
 * Serializes an application Product into a Product for react-autonomy methods.
 */
export const serializeProduct = (
  product: IProduct | ICatalogProduct | IRelatedProductProduct,
  variantSku?: string
): Product => {
  const variant = variantSku
    ? product.variantsCollection.items.find(({ sku }) => sku === variantSku)
    : product.variantsCollection.items[0]
  let description = ''
  let optionValues: Partial<ProductOptionValue[]> = []

  if ('description' in product && typeof product.description === 'string') {
    description = product.description
  } else if (
    'shortDescription' in product &&
    typeof product.shortDescription === 'string'
  ) {
    description = product.shortDescription
  }

  if (
    variant &&
    'optionValuesCollection' in variant &&
    Array.isArray(variant.optionValuesCollection.items)
  ) {
    optionValues = uniqueByKey(
      'slug',
      variant.optionValuesCollection.items.map((value) => {
        return {
          name: value.name,
          presentation: value.presentation,
          slug: value.slug,
          optionTypeName:
            value.linkedFrom?.optionTypeCollection?.items?.[0].slug,
        }
      })
    )
  }

  return {
    url: `${
      typeof window !== 'undefined' ? window.location.origin : ''
    }/products/${product.slug}/`,
    isBundle: false,
    variant: {
      images: [{ largeUrl: product.mainImage?.url }],
      name: product?.name,
      description,
      price: (variant?.price ?? 0).toString(),
      sku: variant?.sku ?? variantSku,
      slug: product.slug,
      optionValues,
    },
  }
}

/**
 * Serializes an External Product into a Product for react-autonomy methods.
 */
export const serializeExternalProduct = (
  externalProduct: IExternalProduct | IRelatedProductExternal
): Product => {
  return {
    url: externalProduct.url,
    isBundle: false,
    variant: {
      description: '',
      images: [{ largeUrl: externalProduct.mainImage?.url }],
      name: externalProduct?.name,
      price: externalProduct?.price.toString(),
      sku: `external-product-${externalProduct?.sys?.id}`,
      slug: `external-product-${externalProduct?.sys?.id}`,
    },
  }
}

/**
 * Serializes an application Product or Bundle into a Product for react-autonomy
 * methods.
 */
export const serializeProductOrBundle = (
  product: ICatalogItem | IProduct | IBundle | IRelatedProduct,
  sku?: string
): Product => {
  switch (product.__typename) {
    case 'Product':
      return serializeProduct(product, sku)
    case 'ExternalProduct':
      return serializeExternalProduct(product)
    case 'Bundle':
      return serializeBundle(product)
    default:
      throw new Error(
        // @ts-expect-error Typescript's understanding of the CMS is not necessarily correct
        `serializeProductOrBundle: unknown produuct type: ${product.__typename}`
      )
  }
}
