/* eslint-disable react/jsx-no-target-blank */
/** @jsxImportSource theme-ui */
import React from 'react'
import { Card, Flex, ThemeUICSSObject } from 'theme-ui'
import ResponsiveImage from '~/components/Generic/Image'
import type { IExternalProduct } from 'graphql-cms/types'
import { useTranslate } from '~/hooks'
import { toUsdCurrency } from '~/utils'
import innerStyles from '~/components/Product/Card/Components/styles.module.css'
import outerStyles from '~/components/Product/Card/styles.module.css'

interface Props {
  externalProduct: IExternalProduct
  maxWidth?: ThemeUICSSObject
  maxHeight?: string
  viewType?: string
  minWidth?: ThemeUICSSObject
}

const ExternalProductCard: React.FC<Props> = ({
  externalProduct,
  maxWidth = '438px',
  maxHeight = '550px',
  viewType = 'grid',
  minWidth = {},
}) => {
  const translate = useTranslate()
  const { mainImage, name, price, url } = externalProduct

  return (
    <Flex
      className={outerStyles.productCard}
      sx={{
        maxWidth,
        ...{ minWidth: minWidth.minWidth },
      }}
    >
      <a href={url} target="_blank" rel="noopener">
        <Card
          className={innerStyles.productCard}
          sx={{ height: 'auto', maxHeight }}
        >
          <Flex className={innerStyles.mainImageContainer}>
            {mainImage && <ResponsiveImage image={mainImage} />}
          </Flex>
          <div
            className={[
              innerStyles.subTextDiv,
              viewType === 'index' && innerStyles.subTextDiv_index,
            ].join(' ')}
          >
            <span
              className={[
                innerStyles.subText,
                viewType === 'index' && innerStyles.subText_index,
              ].join(' ')}
            >
              {toUsdCurrency(price)} USD
            </span>
          </div>
        </Card>

        {viewType !== 'index' && (
          <>
            <Flex
              className={outerStyles.productName}
              sx={{ justifyContent: ['center', null, 'flex-start'] }}
            >
              {name}
            </Flex>
            <Flex
              className={outerStyles.placeButton}
              sx={{ justifyContent: ['center', null, 'flex-start'] }}
            >
              <span>{translate('product.buy_now')}</span>
            </Flex>
          </>
        )}
      </a>
    </Flex>
  )
}

export default ExternalProductCard
